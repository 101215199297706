.card {
  height: 30vh;
  transition: background 0.8s;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-color: rgba(0,0,0,0);
}

.card-col{
  padding-top: 3vh;
  padding-bottom: 3vh;
}

.eval-card {
  background: url("../img/approach/businessmen.png") center center no-repeat;
  background-size: 240px;
  /*filter: invert(100%);*/
  /*-webkit-filter: invert(100%);*/
}
.eval-card:hover {
  background: url("../img/approach/businessmen.png") left center no-repeat;
  background-size: 600px;
  border-color: rgba(0,0,0,1);
}
.eval-card:hover .card-header {
  opacity: 1;
  background-color: #FFFFFF;
}
.eval-card:hover .card-title {
  opacity: 0;
}
.disc-card {
  background: url("../img/approach/research.png") center center no-repeat;
  background-size: 240px;
  /*filter: invert(100%);*/
  /*-webkit-filter: invert(100%);*/
}
.disc-card:hover {
  background: url("../img/approach/research.png") left center no-repeat;
  background-size: 600px;
  border-color: rgba(0,0,0,1);
}
.disc-card:hover .card-header  {
  opacity: 1;
  background-color: #FFFFFF;
}
.disc-card:hover .card-title {
  opacity: 0;
}
.dev-card {
  background: url("../img/approach/agile.png") center center no-repeat;
  background-size: 240px;
  /*filter: invert(100%);*/
  /*-webkit-filter: invert(100%);*/
}
.dev-card:hover {
  background: url("../img/approach/agile.png") left center no-repeat;
  background-size: 600px;
  border-color: rgba(0,0,0,1);

}
.dev-card:hover .card-header  {
  opacity: 1;
  background-color: #FFFFFF;
}
.dev-card:hover .card-title {
  opacity: 0;
}

.launch-card {
  background: url("../img/approach/technical-support.png") center center no-repeat;
  background-size: 240px;
  /*filter: invert(100%);*/
  /*-webkit-filter: invert(100%);*/
}
.launch-card:hover {
  background: url("../img/approach/technical-support.png") left center no-repeat;
  background-size: 600px;
  border-color: rgba(0,0,0,1);
}
.launch-card:hover .card-header  {
  opacity: 1;
  background-color: #FFFFFF;
}
.launch-card:hover .card-title {
  opacity: 0;
}

.card-header {
  color: #000000;
  opacity: 0;
  transition: opacity 1s;

}
